@import url('https://fonts.googleapis.com/css2?family=Chivo+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body, html{
  width: 100%;
  height: 100%;
  margin: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #47B5FF;
}

h1{
  text-align: center;
  color: white;
  
}

h1.header{
  font-family: 'Montserrat';
  width: 450px;
  text-shadow: 1px 2px 2px white;
  color: hsl(0, 0%, 50%);
  animation: glow-effect 3s linear infinite;
}

@keyframes glow-effect{
  from{
    text-shadow: 1px 2px 2px white;
    color: hsl(0,0,50%);
  }
  to{
    text-shadow: none;
    color: white;
  }
}

h1.website{
  text-align: left;
  font-family: 'Chivo Mono';
  font-size: 350%;
  letter-spacing: 1px;
  border-right: 2.5px solid hsl(0,0%,90%);
  animation: blinking 500ms infinite, typeright 6s steps(13) infinite;
  overflow: hidden;
}

@keyframes blinking{
  from{
    border-right-color: hsl(0, 0%, 90%);
  }
  to{
    border-right-color: transparent;
  }
}

@keyframes typeright{
  0%{
    width: 0;
  }
  60%{
    width: 448px;
  }
  85%{
    width: 448px;
  }
  100%{
    width: 0;
  }
}

.circle{
  position: absolute;
  z-index: 0;
  background-color: white;
  aspect-ratio: 1;
  border-radius: 50%;
  box-shadow: 0 0 3px 4px white;
}

.circle-1{
  width: 70px;
  right: 10%;
  bottom: 10%;
}

.circle-2{
  width: 50px;
  bottom: 30%;
  left:5%;
}

.circle-3{
  width: 30px;
  left: 20%;
  top: 50%;
}

.circle-4{
  width: 40px;
  right: 7%;
  top: 40%;
}

.circle-5{
  width: 35px;
  left: 15%;
  top: 30%;
}

.circle-6{
  width: 30px;
  right: 20%;
  top: 50%;
}

.container{
  width: 330px;
  height: auto;
  background-color: white;
  position: relative;
  z-index: 1;
  border-radius: 3.5%;
  box-shadow: 2px 2px 5px 3px #EAF6F6;
  color: black;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  margin: auto;
  top: 50px;
}

.city, .job{
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

label{
  font-size: 1.3rem;
  margin: 10px;
  font-family: 'Chivo Mono';
  text-align: center;
}

input[type=search]{
  width: 250px;
  padding: 7px;
  font-family: 'Poppins';
  font-size: 1rem;
  height: 2.2rem;
  border-radius: 5px;
  border: 2px solid #CFD2CF;
  margin: 5px auto;
}

:is(#city,#job)::-webkit-search-cancel-button{
  cursor: pointer;
}

#submit{
  background-color: black;
  margin: 10px auto 15px auto;
  color: white;
  border: none;
  width: 100%;
  height: 3rem;
  border-radius: 8px;
  font-size: 1.2rem;
  letter-spacing: 1px;
  font-family: 'Chivo Mono';
  cursor: pointer;
}

#submit:active{
  opacity: 0.75;
  transform: scale(0.97);
}

input[type=search]:focus{
  outline:1px solid darkgrey;
}

.showListCity,.showListJobs{
  list-style: none;
  width: 200px;
  padding: 0;
  border: 1px solid white ;
  border-right: none;
  border-top: none;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  overflow: auto;
  position: absolute;
  background-color: white;
  left: 65px;
  display: none;
}

.showListCity{
  top: 106px;
  animation: appearCity 400ms ease;
}

.showListJobs{
  top: 220px;
}

:is(.showListCity,.showListJobs).appear{
  display: block;
}

.showListCity.appear{
  max-height: 286px;
  animation: appearCity 400ms ease;
}

@keyframes appearCity{
  from{
    max-height: 0;
  }
  to{
    max-height: 286px;
  }
}

.showListJobs.appear{
  max-height: 198px;
  animation: appearJob 300ms ease;
}

@keyframes appearJob{
  from{
    max-height: 0;
  }
  to{
    max-height: 198px;
  }
}

li.list-item{
  padding: 3.5px 0;
  height: 15px;
  font-family: 'Chivo Mono';
  font-size: 13px;
  cursor: pointer;
  width:  183.2px;
}

li:hover{
  background-color: hsl(0,0%,80%);
}